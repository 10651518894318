import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"
import Row from "../components/bootstrap/row"

import AwardCategoryShortlist from "../components/award-category-shortlist"
import ShortlistOrganisation from "../components/shortlist-organisation"
import PrimaryButton from "../components/primary-button"

const ShortlistPage = () => (
  <Background>
    <Layout>
      <Seo title="Shortlist" />
      <div hidden>
        <Container>
          <PageTitle text="Shortlist" />
        <div style={{
          textAlign: `center`,
          backgroundColor: `rgba(0,0,0,0.6)`
        }}>
          <div style={{
            maxWidth: `34rem`,
            margin: `auto`,
            padding: `5rem 2rem 1rem 2rem`
          }}>
            <span style={{
              display: `inline`,
              backgroundColor: `#d23464`,
              padding: `.5rem 0`,
              lineHeight: `3.4rem`,
              boxShadow: `.5rem 0 0 #d23464, -.5rem 0 0 #d23464`,
              textTransform: `uppercase`,
              color: `#fff`,
              fontFamily: `Din Pro`,
              fontWeight: `700`,
              fontSize: `1.6rem`
            }}>Key Dates</span>
          </div>
          <Container>
            <div className="row" style={{
                  color: `#fff`,
                  padding: `2rem`
                }}>
              <div className="col-md">
                <h4 style={{
                  textTransform: `uppercase`,
                  fontSize: `1.2rem`
                }}>Entry Deadline</h4>
                <p>Closed on 9th December 2022</p>
              </div>
              <div className="col-md">
                <h4 style={{
                  textTransform: `uppercase`,
                  fontSize: `1.2rem`
                }}>Shortlist Announcement</h4>
                <p>23rd January 2023</p>
              </div>
              <div className="col-md">
                <h4 style={{
                  textTransform: `uppercase`,
                  fontSize: `1.2rem`
                }}>Awards Ceremony Date</h4>
                <p>23rd March 2023</p>
              </div>
            </div>
          </Container>
        </div>

        <div style={{
          fontFamily: `Din Pro`,
          fontWeight: `400`,
          fontSize: `1rem`,
          color: `#fff`,
          background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`
        }}>
          <Container>
            <div className="row" style={{ padding: `2rem`}}>
              <div className="col-lg">
                <h5>Thank You</h5>
                <p>The entry deadline has now passed and your nominations are being evaluated by our judges.</p>
                <p>We will be announcing the shortlist on this page in January with the awards ceremony taking place in March.</p>

                <h5>Questions</h5>
                <p>If you have any questions, please email us on <a href="mailto:entries@smarterworkinglive.com" style={{color: `#fff`}}>entries@smarterworkinglive.com</a> or call <a href="tel:+441616973438" style={{color: `#fff`}}>0161 697 3438</a>.</p>
              </div>
              <div className="col-lg">
                <h5>Reminder</h5>
                <p>Please enter your email address and click submit to receive a reminder when the shortlist is announced.</p>

                <form
                  id="entries"
                  name="entries"
                  method="post"
                  onSubmit={(e) => {
                    e.preventDefault()
                    let myForm = document.getElementById('entries')
                    let formData = new FormData(myForm)

                    fetch('https://hook.eu1.make.com/oc4q2v3c3wu4dqi8r38ivy7sk7ppi3w1', {
                      method: 'POST',
                      headers: { "Content-Type": "application/x-www-form-urlencoded" },
                      body: new URLSearchParams(formData).toString()
                    }).then(() => { 
                      console.log('Form successfully submitted')
                      document.getElementById('formstatus').innerHTML = '<p>Thank you for registering for the shortlist reminder. We will be in touch when the shortlist is announced.</p>'
                      if(window.dataLayer) {
                        window.dataLayer.push({event: 'form-registration-download', 'gtm.elementId': 'entries', 'gtm.elementUrl': undefined});
                      }
                    }).catch((error) => alert(error))
                  }}
                >
                  <p style={{display: `none`}}>
                    <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
                  </p>
                  <input type="hidden" name="form-name" value="entries" />
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <input type="text" className="form-control" id="Email" name="email" placeholder="Email" required />
                      </div>
                    </div>
                  </div>
                  <div id="formstatus"></div>
                  <button type="submit" className="btn btn-primary" style={{
                    backgroundColor: `rgb(255, 255, 255)`,
                    borderColor: `rgb(255, 255, 255)`,
                    color: `rgb(210, 52, 100)`,
                    textTransform: `uppercase`,
                    borderRadius: 0,
                    padding: `0.6rem 1rem`,
                    fontWeight: 600,
                  }}>Submit</button>
                </form>

              </div>
            </div>
          </Container>
        </div>

        <br /><br />

        </Container>
      </div>
      <div>
        <Container>
          <PageTitle text="Shortlist" />
          <p style={{
            textTransform: `uppercase`,
            color: `#fff`,
            fontWeight: `bold`,
            textAlign: `center`,
            maxWidth: `750px`,
            margin: `auto`,
            marginBottom: `50px`,
          }}>Congratulations to all organisations who have made it to the shortlist.</p>

          <div className="text-center mb-5">
            <PrimaryButton to="/awards/" text="Book a table for the ceremony" />
          </div>

          <AwardCategoryShortlist title="Improving Spaces" subtitle="Creating a Great Place to Work" gridColor="#d23464" icon="computer-desk">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Defence Equipment and Support" project="Smarter Working Floorplate Pilots" logo="" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Forestry and Land Scotland" project="Creating a Great Place to Work" logo="dio" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="London School of Economics and Political Science" project="Future Ways of Working Programme: Improving Spaces" logo="hmrc" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Midlothian Council" project="Making it Happen: Testing an Approach to Improving Wellbeing" logo="nsandi" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Office for National Statistics" project="Evolving the Workplace" logo="hpcc" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Registers of Scotland" project="Creating a Great Place to Work" logo="hmrc" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="SQA" project="Smarter Working Programme – Reopening of Our Offices" logo="nsandi" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />
          
          <AwardCategoryShortlist title="Future Focused and Sustainable Property" gridColor="#30c4bf" icon="office">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Buckinghamshire Council" project="Work Smart – Pilot Workspace" logo="" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Hertfordshire County Council" project="Farnham & Robertson House Refurbishment" logo="dio" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="National Probation Service" project="Redfern Probation Office, Manchester" logo="dof" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="NHS Business Service Authority" project="Towards Net-Zero: Greenfinch Way" logo="hmrc" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Office for National Statistics" project="Sustainable Properties and the Future ONS" logo="nsandi" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="South Yorkshire Police" project="Smarter Ways of Working" logo="hpcc" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />
          
          <AwardCategoryShortlist title="Digital Transformation" subtitle="Enabling New Ways of Working" gridColor="#30c4bf" icon="arrows-cog">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Birmingham City Council" project="An Open Platform for Field Workers" logo="bmbc" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Department for Transport" project="Future of Shared Services (FOSS) Programme - Process Mapping (Promapp)" logo="cps" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="HM Revenue & Customs" project="Open Banking Payment Innovation" logo="dfe" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="NHS Shared Business Services" project="Robotic Process Automation – NHS SBS and Lewisham and Greenwich NHS Trust" logo="bmbc" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Norfolk County Council" project="Transformational Smarter Working Technology for Immersive Meetings" logo="cps" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Nottinghamshire Healthcare NHS Trust" project="Implementation of Isla Care visual platform to support clinical practice in a Community Stroke Team" logo="dfe" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Office for National Statistics" project="Digital Services & Technology and Evolving the Workplace" logo="hmrc" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Somerset County Council" project="Somerset's Journey to Smarter Working & Social Value" logo="rchnhs" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Tewkesbury Borough Council" project="Transforming Bulky Waste" logo="hmrc" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="The Leeds Teaching Hospitals NHS Trust" project="Corporate & Health Records – A Paper Light approach" logo="rchnhs" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />
          
          <AwardCategoryShortlist title="Automation, Artifical Intelligence and Machine Learning" gridColor="#d23464" icon="brain">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="HM Treasury" project="Robot Process Automation Project" logo="bmbc" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Sandwell & West Birmingham NHS Trust" project="Merge Clinical Review AI (Artificial Intelligence)" logo="cps" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Suffolk County Council" project="Alleviating pressure on Children and Young People's Service and Adult and Community Service with RPA" logo="dfe" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Swindon Borough Council" project="Munchie, the Free School Meals Bot" logo="hmrc" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Thames Valley Police and Hampshire Constabulary" project="Robotic Process Automation Project" logo="rchnhs" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Virtually Healthcare" project="GP Express" logo="rchnhs" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />
          
          <AwardCategoryShortlist title="Harnessing the Value of Data" gridColor="#d23464" icon="binary">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="All Saints Catholic College" project="Lighthouse" logo="bmbc" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Department for Business & Trade" project="Using Building Data to Improve Ways of Working" logo="cps" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="DWP Digital" project="Harnessing Systrack Data to Protect Government Assets" logo="dfe" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="NHS National Services Scotland" project="Assure Information Management System (AIMS)" logo="hmrc" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="North Sea Transition Authority" project="Harnessing Data to Drive Net Zero and Energy Security" logo="rchnhs" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Wakefield District Health and Care Partnership" project="Using System Data to Underpin Service Redesign" logo="rchnhs" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />
          
          <AwardCategoryShortlist title="Small-scale, Big Impact" gridColor="#30c4bf" icon="inward-arrows-square">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Blackburn with Darwen Council" project="Changing Futures Programme" logo="bmbc" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Eastman Dental Hospital, University College London Hospitals" project="Virtual Platform to Improve Children's Access to Dental Care" logo="cps" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Kent County Council" project="Kent Estates & Kent Connects Shared Workspace" logo="dfe" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Somerset NHS Foundation Trust" project="Small Team, Big Impact: How Our Team Improved the Delivery of Patient Care in the Community." logo="hmrc" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="South Eastern Regional College" project="Entrepreneur Club" logo="rchnhs" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Swindon Borough Council" project="Smart HWRC - No More Queuing Picnics!" logo="rchnhs" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Tewkesbury Borough Council" project="Transforming Bulky Waste" logo="hmrc" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="University Hospitals Birmingham" project="Cancer Health and Well-Being Virtual Patient Programme: Supporting Patients Post Cancer Treatment" logo="rchnhs" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />
          
          <AwardCategoryShortlist title="Building Collaborative Communities" gridColor="#30c4bf" icon="circle-people">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Army HQ - Information Directorate" project="MyVoice Calling - Improving Communications on Base" logo="coleg" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Ayrshire College" project="Connecting Communities" logo="dfe" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="FutureNHS team within NHS England" project="Stepping up FutureNHS and Collaborative Communities Across the NHS: For The Pandemic and Recovery" logo="dfe" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Hertfordshire County Council" project="Building Collaborative Communities with Touchdowns in Remote Areas" logo="rbfb" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Kent County Council" project="Kent Estates & Kent Connects Shared Workspace" logo="dfe" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Liverpool School of Tropical Medicine" project="Liverpool Vaccine Equity Project" logo="nelc" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="London Borough of Redbridge" project="Let's Make Tech Inclusive" logo="rbfb" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />
          
          <AwardCategoryShortlist title="Putting People First" gridColor="#d23464" icon="inspire">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Ayrshire College" project="Connecting Communities" logo="nsandi" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Babergh & Mid Suffolk District Councils" project="Wellbeing Support Programme" logo="nhsemployers" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Companies House" project="Making People Our Priority" logo="nhsemployers" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Folkestone & Hythe District Council" project="Wellbeing Strategy: Supporting a Happy and Healthy Workplace" logo="nhslpp" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="HM Prison & Probation Service" project="Business Strategy and Change Health and Wellbeing Project" logo="rbfb" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Nuclear Waste Services" project="A Transformation Programme" logo="nhslpp" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Platform Housing Group" project="Our Five Pillars of Wellbeing" logo="rbfb" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Royal Papworth Hospital Foundation Trust" project="Laudit" logo="nhsemployers" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Salisbury NHS Foundation Trust" project="COVID-19 Recovery Wellbeing Project" logo="nhslpp" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="University Hospitals Birmingham" project="Cancer Health and Well-Being Virtual Patient Programme: Supporting Patients Post Cancer Treatment" logo="rbfb" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />
          
          <AwardCategoryShortlist title="Championing Continuous Improvement" gridColor="#d23464" icon="three-up-arrows">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Department for Digital, Culture, Media & Sport" project="Smarter Hybrid Working Programme" logo="gmphcl" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="HM Land Registry" project="Local Land Charges Programme" logo="lpnt" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="HM Prison & Probation Service" project="Continuous Improvement Pathway" logo="lpnt" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="HM Revenue & Customs" project="Customer Strategy & Tax Design (CS&TD) PAS3000 GAP Analysis Review" logo="mpnt" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="University of Strathclyde" project="Embedding a Culture of Continuous Improvement in our Library and Information Resources" logo="yas" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Wirral Community Health and Care NHS Foundation Trust" project="NELPs Will Help! Introduction and Continuous Improvement of Nominated Education Link Professionals" logo="yas" />
              </div>
            </Row>
          </AwardCategoryShortlist>
          
          <br /><br />
          
          <AwardCategoryShortlist title="Outstanding Smarter Working Leadership" gridColor="#30c4bf" icon="leader">
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="2 Signal Regiment Light Aid Detachment, British Army" project="Providing Digital Leadership in Process Transformations" logo="dvsa" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Department for Business & Trade" project="Our Leaders as Smarter Working Drivers" logo="hmrc" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Kent County Council" project="Kent Estates & Kent Connects Shared Workspace" logo="hmrc" />
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Stockport Metropolitan Borough Council" project="Adult Mental Health Discharge" logo="nhssbs" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="Transport for Greater Manchester" project="Wellbeing at TfGM" logo="wmp" />
              </div>
              <div className="col-lg">
                <ShortlistOrganisation organisation="West Berkshire Council" project="Extending Accessibility of Democratic Processes" logo="hmrc" />
              </div>
            </Row>
          </AwardCategoryShortlist>

          <div className="text-center mt-5">
            <PrimaryButton to="/awards/" text="Book a table for the ceremony" />
          </div>

          <br /><br /><br /><br />

        </Container>
      </div>
    </Layout>
  </Background>
)

export default ShortlistPage
